import React, {Component} from 'react'
import Spinner, {SpinnerContext} from "../components/YesNo/Spinner"
import ResultBlock from "../components/YesNo/ResultBlock"
import Menu from "../components/Menu";
import Banner from "../components/Banner";
import {Helmet} from "react-helmet"

class YesNo extends Component {
    constructor(props) {
        super(props);

        this.btnReset = () => {
            this.setState({
                answers: {
                    yes: 0,
                    no: 0
                }
            })
        };

        this.btnSpin = ({speed}) => {
            if (!this.state.animate) {
                const rand = Math.round(1 + Math.random() * (8 - 1));
                const end_position = this.state.end_position + 1800 + (rand * 45);
                const answer = parseInt((end_position - (parseInt(end_position / 360) * 360)) / 45);
                this.setState({
                    animate: true,
                    start_position: this.state.end_position,
                    end_position,
                    speed,
                });
                setTimeout(
                    function () {
                        this.setState({
                            animate: false,
                            answers: {
                                yes: (answer === 1 || answer === 3 || answer === 5 || answer === 7) ? this.state.answers.yes + 1 : this.state.answers.yes,
                                no: (answer === 0 || answer === 2 || answer === 4 || answer === 6) ? this.state.answers.no + 1 : this.state.answers.no
                            }
                        });
                    }.bind(this),
                    (10 / speed) * 1000
                );
            }
        };

        this.state = {
            animate: false,
            start_position: 22.5,
            speed: 5,
            end_position: 22.5,
            btnSpin: this.btnSpin,
            btnReset: this.btnReset,
            answers: {
                yes: 0,
                no: 0
            }
        };
    }

    render() {
        return (
            <SpinnerContext.Provider value={this.state}>
                <Helmet>
                    <title>{this.props.title}</title>
                    <meta name="description" content={this.props.meta} />
                </Helmet>
                <div className="row justify-content-between content">
                    <div className="col-lg-8 col-xs-12 col-sm-12">
                        <Spinner withprobably={false} title={this.props.header || this.props.title}/>
                        {this.props.description}
                    </div>
                    <div className="col-lg-4 col-xs-12 col-sm-12">
                        <Menu {...{parent: {className: "main-menu"}, short: true}}/>
                        <ResultBlock withprobably={false}/>

                        {window.innerWidth >= 1048 &&
                        (<div id="ads_right">
                            <Banner id={'R-A-454127-2'} currentPath={this.props.title}/>
                        </div>)
                        }
                    </div>
                </div>
            </SpinnerContext.Provider>
        );
    }
}

export default YesNo