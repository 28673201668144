import React, {Component} from 'react'
import PropTypes from 'prop-types'
import '../../assets/scss/spinner.scss'
import classnames from 'classnames'
import Banner from "../Banner";
import YandexShare from "../YandexShare";

export const SpinnerContext = React.createContext();

class Spinner extends Component {

    static contextType = SpinnerContext;

    render() {
        const {speed, start_position, end_position, animate} = this.context;
        const {withprobably} = this.props;
        const css = `
        .arrow2.animate{
            animation-name: rotate;
        }
@keyframes rotate {
  0% {
    transform: rotate(` + start_position + `deg);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(` + ((start_position + end_position) / 2) + `deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(` + end_position + `deg);
  }
}`;
        return <React.Fragment>
            {   window.innerWidth >= 1048 &&
            (<div id="ads_top">
                    <Banner currentPath={this.props.title} id={'R-A-454127-1'} format={"horizontal"}/>
                </div>)
            }
            <h1>{this.props.header || this.props.title}</h1>
            {window.innerWidth < 1048 &&
            (<div id="ads_top_mobile">
                <Banner currentPath={this.props.title} id={'R-A-454127-3'}/>
            </div>)
            }
            <div id="spinner_block">
                <div id={"btn-speed"}>
                    <div className="mask" style={{height: this.state.width + "%", width: this.state.width + "%"}}>
                        <div className="line"></div>
                    </div>
                </div>
                <style>{css}</style>
                <div className={classnames("spinner", {withprobably})}>
                    <div className="ellipse31">
                        <div className="group58">
                            <div className="group57 group">
                                <div className="vector2"></div>
                                <div className="vector21"></div>
                                <p className="yes">Да</p>
                                <p className="no">Нет</p>
                            </div>
                            <div className="group571 group">
                                <div className="vector2"></div>
                                <div className="vector21"></div>
                                <p className="no">Нет</p>
                                <p className="yes">Да</p>
                            </div>
                            <div className="group572 group">
                                <div className="vector2"></div>
                                <div className="vector21"></div>
                                <p className={classnames({
                                    no: !withprobably,
                                    probably: withprobably
                               })}>{withprobably ? 'Наверное' : 'Нет'}</p>
                                <p className={classnames({yes: !withprobably, no: withprobably})}>Да</p>
                            </div>
                            <div className="group573 group">
                                <div className="vector2"></div>
                                <div className="vector21"></div>
                                <p className={classnames({no: !withprobably, yes: withprobably})}>Нет</p>
                                <p className={classnames({
                                    yes: !withprobably,
                                    probably: withprobably
                                })}>{withprobably ? 'Наверное' : 'Да'}</p>
                            </div>
                        </div>
                        <div className="ellipse3">
                            <div className="arrow">
                                <button className="ellipse">
                                    Крутить
                                </button>
                                <div onMouseDown={this.onMouseDown}
                                     onMouseUp={this.onMouseUp}
                                     onMouseOut={this.onMouseOut}
                                     onTouchStart={this.onMouseDown}
                                     onTouchEnd={this.onMouseUp}
                                     className={classnames('arrow2', {animate})} style={{
                                    animationDuration: 10 / speed + 's',
                                    transform: 'rotate(' + end_position + 'deg)'
                                }}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button onClick={this.context.btnReset} id={"btn-reset"}>Гадать заново</button>
            <div id="ads_bottom">
                {
                    window.innerWidth < 1048 &&
                    <Banner currentPath={this.props.title} id={'R-A-454127-4'}/>
                }
            </div>
            <YandexShare/>
        </React.Fragment>;
    }


    onMouseDown = () => {
        if (!this.context.animate) {
            this.setState({width: 50});
            clearInterval(this.interval);
            this.interval = setInterval(
                function () {
                    if (this.state.width === 0 || this.state.width === 100) {
                        this.setState({direction: this.state.direction * -1});
                    }
                    this.setState({width: this.state.width + this.state.direction});
                }.bind(this),
                this.state.speed
            );
        }
    };
    onMouseUp = () => {
        if (!this.context.animate) {
            clearInterval(this.interval);
            this.context.btnSpin({speed: this.state.width / 20});
        }
    };
    onMouseOut = () => {
        if (!this.context.animate) {
            clearInterval(this.interval);
        }
    };

    constructor(props, context) {
        super(props, context);
        this.state = {
            speed: 10,
            width: 50,
            direction: 1
        };
        this.interval = null;
    }

}

Spinner.propTypes = {
    withprobably: PropTypes.bool.isRequired
};
export default Spinner